export const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: [
      'title',
      'date',
      'start_date',
      'end_date',
      'unit',
      'education',
      'location',
      'year',
      'link',
    ],
    properties: {
      date: {
        title: '申請日期',
        type: 'string',
      },
      start_date: {
        title: '開始刊登日期',
        type: 'string',
      },
      end_date: {
        title: '結束刊登日期',
        type: 'string',
      },
      title: {
        title: '職位',
        type: 'string',
      },
      unit: {
        title: '單位',
        type: 'string',
      },
      education: {
        title: '要求學歷/科系',
        type: 'string',
      },
      location: {
        title: '工作地點',
        type: 'string',
      },
      year: {
        title: '年資',
        type: 'string',
      },
      link: {
        title: '徵才連結',
        type: 'string',
      },
      agree: {
        type: 'boolean',
        title:
          '本會保留本次徵才刊登審核之權限，請再確實檢查以上徵才條件是否有違反相關法令！',
        default: false,
      },
    },
  },
  uiSchema: {
    date: {
      'ui:help': '格式為yyyy-mm-dd, 例如: 2024-01-23',
    },
    end_date: {
      'ui:help': '結束刊登日期以30天為限',
    },
  },
};

import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Theme from '../../Theme';
import * as Ant from 'antd';
import qs from 'query-string';
import {ArrowLeftShort} from '@styled-icons/bootstrap/ArrowLeftShort';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {formSpec} from './form-spec';
import {useOutlet} from 'reconnect.js';

const pageSize = 20;
const pageRoute = '/profile/job-request/';
const collectionName = 'job';

function ProfileJobRequest(props) {
  const [user] = useOutlet('user');
  const {action} = qs.parse(props.location.search);
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {owner: user.sub};
      const resp = await JStorage.fetchDocuments(
        collectionName,
        extraQuery,
        ['-date'],
        paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging, user]);

  React.useEffect(() => {
    async function fetchData() {
      if (action !== 'create') {
        fetchDocumentList();
      }
    }

    fetchData();
  }, [fetchDocumentList, action]);

  if (action === 'create') {
    return (
      <Wrapper id="twpaa-job-request">
        <a
          href={pageRoute}
          style={{display: 'flex', alignItems: 'center', marginBottom: 20}}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(pageRoute);
          }}>
          <ArrowLeftShort size={24} />
          <div style={{fontSize: 20}}>返回列表</div>
        </a>

        <h1 style={{fontSize: 28}}>申請徵才服務</h1>

        <GenericForm
          {...formSpec}
          renderCustomSubmitButton={({values: formData}) => {
            return (
              <Ant.Button
                type="primary"
                style={{marginTop: 20}}
                onClick={async () => {
                  if (!formData.agree) {
                    window.alert(
                      '務必勾選 本會保留本次徵才刊登審核之權限 (最後一個欄位) 才可送出表單',
                    );
                    setTimeout(() => AppActions.setLoading(false), 100);
                    return;
                  }

                  for (const field of formSpec.schema.required) {
                    if (!formData[field]) {
                      Ant.message.warning(`請填寫所有欄位後再行送出!`);
                      setTimeout(() => {
                        AppActions.setLoading(false);
                      });
                      return;
                    }
                  }

                  try {
                    AppActions.setLoading(true);
                    await JStorage.createDocument('job', {
                      ...formData,
                      owner: user.sub,
                      status: '審核中',
                    });
                    Ant.message.success('已成功送出');
                    AppActions.navigate(pageRoute);
                  } catch (ex) {
                    console.warn('REV_DBG', ex);
                    AppActions.setLoading(false);
                  }
                }}>
                確認送出
              </Ant.Button>
            );
          }}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>徵才服務登記</h1>

      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Ant.Button
          type="outlined"
          style={{marginBottom: 20, borderRadius: 8}}
          onClick={() => {
            AppActions.navigate(`${pageRoute}?action=create`);
          }}>
          提出申請
        </Ant.Button>
      </div>

      <Ant.Table
        columns={[
          {
            title: '申請日期',
            key: 'date',
            width: 120,
            render: (_, record) => {
              return (
                <div>
                  <div>{record.date}</div>
                </div>
              );
            },
          },
          {
            title: '詳情',
            key: 'title',
            render: (_, record) => {
              return (
                <div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    刊登區間: {record.start_date || '---'} ~{' '}
                    {record.end_date || '---'}
                  </div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    職位: {record.title}
                  </div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    單位: {record.unit}
                  </div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    學歷: {record.education}
                  </div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    地點: {record.location}
                  </div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    年資: {record.year}
                  </div>
                  <div
                    style={{borderBottom: '1px solid #ccc', padding: '6px 0'}}>
                    連結: {record.link}
                  </div>
                </div>
              );
            },
          },
          {
            title: '狀態',
            key: 'status',
            dataIndex: 'status',
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  &#twpaa-job-request {
    .form-group.field.field-string {
      margin-left: 20px;
      padding-top: 30px;
      padding-bottom: 6px;
      border-bottom: 1px solid #ccc;
    }

    .ant-row.ant-form-item-row {
      .ant-col.ant-col-24.ant-form-item-label {
        flex: 0 0 80px;
      }
      .ant-col.ant-col-24.ant-form-item-control {
        flex: 1 1 calc(100% - 100px);
      }
    }
  }
`;

export default withPageEntry(ProfileJobRequest);
